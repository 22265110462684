import { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import StructuredData from 'components/content-blocks/partials/structured-data';

import './styles/reviews.scss';
import Slider from '../sliders/slider';
import Wysiwyg from './partials/wysiwyg';

const Reviews = props => {
  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              structured_data {
                capterra_reviews
              }
              reviews {
                review {
                  attribution
                  copy
                  rating
                  store
                  title
                }
                store_logos {
                  app_store {
                    alt_text
                    source_url
                  }
                  google_play {
                    alt_text
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const breakpoints = useBreakpoint();

  const pageContext = data.allWordpressAcfOptions.edges[0].node.options;
  const logos = pageContext.reviews.store_logos;
  const structuredData = pageContext.structured_data.capterra_reviews;

  const card = pageContext.reviews.review.map((card, index) => {
    return {
      title: card.title,
      sub_title: card.attribution,
      copy: card.copy,
      store: card.store,
      rating: card.rating,
      image: card.store === 'App Store' ? logos.app_store : logos.google_play,
      id: index,
    };
  });

  return (
    <section className="reviews-section reviews">
      <header>
        <h2 className="sub-title">{props.sub_title}</h2>
        <h3 className="title">{props.title}</h3>
        <Wysiwyg wysiwygClasses="copy-container" content={ props.copy } />
      </header>
      <div className="reviews-container">
        {breakpoints.sm ? (
          <Slider
            card={ card }
            startingIndex={ 9 }
            hasSubTitle
            hasTitle
            hasCopy
            hasImage
            hasNoDots
            sliderStyle={ { padding: '0px calc(46% - 120px)', overflowX: 'clip', overflow: '-webkit-paged-x' } }
          />
        ) : (
          <Fragment>
            <Slider
              card={ card.slice(0, 8) }
              hasSubTitle
              hasTitle
              hasCopy
              hasImage
            />
            <Slider
              card={ card.slice(8, 16) }
              hasSubTitle
              hasTitle
              hasCopy
              hasImage
            />
          </Fragment>
        )}
      </div>
      <StructuredData data={ structuredData } />
    </section>
  );
};

export default Reviews;
