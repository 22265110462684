import './styles/manager.scss';
import Phones from './smart-phone';

const Manager = ({
  actions,
  face_icons,
  phone_case_background,
  app_logos,
  ...props
}) => {
  return (
    <section
      className="manager-section section manager"
      data-section="managerSlider"
    >
      <div className="container manager-container">
        <div className="manager-intro has-text-centered">
          <h2 className="sub-title">{props.sub_title}</h2>
          <h3 className="title">{props.title}</h3>
          <p className="copy">{props.copy}</p>
        </div>
        <Phones
          actions={ actions }
          appLogos={ app_logos }
          faceIcons={ face_icons }
          backgroundImages={ phone_case_background }
          notSwipeable={ props.notSwipeable }
        />
      </div>
    </section>
  );
};

export default Manager;
