
import { unescape } from 'lodash';

import FontIcon from 'components/icons';

import './styles/x-by-x.scss';

type XByXProps = {
  activeFeatureIndex: number,
  features: any[],
  setActiveFeatureIndex: (number) => void,
  x?: number,
}

type ItemProps = {
  active: boolean
  altText: string,
  contentList?:any,
  description: string,
  icon?: string,
  iconMarkup: string,
  iconMaxWidth: string,
  iconSprite: boolean,
  imageUrl: string,
  index: number,
  onClickHandler: () => void,
  title: string,
  url: any
}

const XByX = ({ features, activeFeatureIndex, setActiveFeatureIndex, x = 2 }:XByXProps) => {
  const handleItemClick = i => {
    setActiveFeatureIndex(i);
  };

  const rows : React.ReactElement[] = [];

  for (let rowIndex = 0; rowIndex < features.length; rowIndex += x) {
    rows.push(
      <div className="x-by-x-row">
        {features.slice(rowIndex, rowIndex + x).map(
          (
            {
              title,
              body,
              iconMarkup,
              iconMaxWidth,
              content_list,
              mobileScreenshot,
              url,
              icon,
              icon_from_library,
              icon_width,
              mobile_screenshot,
              alt_text,
            },
            slicedIndex
          ) => {
            return (
              <Item
                key={ title }
                title={ title }
                description={ body }
                iconSprite={ icon_from_library || undefined }
                contentList={ content_list || undefined }
                iconMarkup={ iconMarkup || unescape(icon) }
                iconMaxWidth={ iconMaxWidth || icon_width }
                active={ activeFeatureIndex === slicedIndex + rowIndex }
                onClickHandler={ () => handleItemClick(slicedIndex + rowIndex) }
                url={ url }
                imageUrl={ mobileScreenshot || mobile_screenshot.source_url }
                index={ slicedIndex + rowIndex }
                altText={
                  typeof mobileScreenshot === 'undefined'
                    ? alt_text
                    : mobileScreenshot.alt_text
                }
              />
            );
          }
        )}
      </div>
    );
  }

  return (
    <div className="x-by-x">
      {rows}
    </div>
  );
};

function Item({
  active,
  onClickHandler,
  iconSprite,
  iconMarkup,
  iconMaxWidth,
  contentList,
  icon,
  title,
  description,
  imageUrl,
  altText,
}: ItemProps) {

  return (
    <div className="item-container">
      <button className={ `item-button ${active ? 'active' : ''}` } onClick={ onClickHandler } type="button">
        <div className="btn-focus" tabIndex={ -1 }>
          <header className="item-header">
            <div className="item-icon"
              dangerouslySetInnerHTML={ iconSprite ? undefined : { __html: iconMarkup || icon } as any }
              style={ { 'maxWidth': iconMaxWidth } }
            >
              {iconSprite && <FontIcon icon={ iconSprite } />}
            </div>
            <h3 className="item-title"> {title} <FontIcon icon="chevron-right" /></h3>
          </header>
          {contentList && (
            <div className="content-list">
              <h4 className="">{contentList.title}</h4>
              <div className="wysiwyg-section" dangerouslySetInnerHTML={ { __html: contentList.list_items } } />
            </div>

          )}
          <p className="item-copy"> {description} </p>
        </div>
      </button>
      <div className="bubble-container">
        <div className="bubble">
          <img
            loading="lazy"
            height="134"
            src={ imageUrl }
            alt={ altText || 'app screenshot' }
          />
        </div>
        <div className="bubble-border" />
      </div>
    </div>
  );
}

export default XByX;
