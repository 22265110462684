import { Fragment } from 'react';
import Slider from 'components/sliders/slider';

import './styles/highlight.scss';

const Highlight = props => {
  return (
    <section
      className={ `section highlight highlight-section ${props.className || ''}` }
    >
      {props.header && (
        <Fragment>
          {props.header.title && (
            <h2 className="headline has-text-centered">{props.header.title}</h2>
          )}
          {props.header.sub_title && (
            <h3 className="subheadline has-text-centered">
              {props.header.sub_title}
            </h3>
          )}
          {props.header.copy && (
            <p className="copy has-text-centered">{props.header.copy}</p>
          )}
        </Fragment>
      )}
      <Slider
        { ...props }
        startingIndex={
          typeof props.startingIndex === 'undefined'
            ? 0
            : Number(props.startingIndex)
        }
      />
    </section>
  );
};

export default Highlight;
