type Props = {
  alt?: string
  desktopHeight?: any
  desktopWidth?: any
  height?: any
  loading?: 'eager' | 'lazy' | undefined
  src: string
  srcSetAvig?: boolean | string
  srcSetPath?: string
  srcSetWebp?: boolean | string
  width?: any
}

const Image = ({
  alt,
  desktopHeight,
  desktopWidth,
  loading,
  src,
  srcSetPath,
}:Props) => (
  <picture className="picture-container">
    {srcSetPath && <source srcSet={ srcSetPath } media="(max-width: 768px)" />}

    <img
      loading={ loading }
      className="picture-image"
      src={ src }
      width={ desktopWidth }
      height={ desktopHeight }
      alt={ typeof alt === 'undefined' ? 'When I Work | alt text' : alt }
    />
  </picture>
);

export default Image;
