import { ReactNode } from 'react';

import ACFSignup from 'types/ACFSignup';

import SchedulingSignup from 'components/modal/signups';

import './styles/landing.scss';
import DefaultLandingHero from './partial/default-landing-hero';

type HeroLandingProps = {
  contentBeforeDefaultLanding: ReactNode,
  children: ReactNode
  defaultLandingHero: any
  launch_modal_button_text: string,
  modal_submit_button_text: string,
  modal_title: string,
  signup: ACFSignup,
  title: string,
  uniqueId: string
};

export const HeroLanding = ({
  title,
  modal_title,
  uniqueId,
  signup,
  defaultLandingHero,
  launch_modal_button_text,
  modal_submit_button_text,
  contentBeforeDefaultLanding,
  children,
}:HeroLandingProps) => {

  return (
    <section
      className="section landing-hero-section"
      data-section="landing page hero"
    >
      <div className="container">
        <h1 className="headline has-text-centered">{ title }</h1>
        <div className="signup-cta-container">
          <SchedulingSignup
            uniqueId={ uniqueId }
            modalTitle={ modal_title }
            showInitFields={ true }
            buttonInline
            legalText={ signup.legal_text }
            fieldLayout="row"
            signupModalLaunch={ launch_modal_button_text }
            signupModalSubmit={ modal_submit_button_text }
          />
        </div>
        { contentBeforeDefaultLanding }
        { defaultLandingHero && <DefaultLandingHero { ...defaultLandingHero } />}
        { children }
      </div>
    </section>
  ); 
};