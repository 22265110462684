import { useState, Fragment } from 'react';

import Link from 'components/link/link';

import Slider from '../sliders/phones';

const Phones = ({ appLogos, actions, backgroundImages, faceIcons, notSwipeable }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const mobilePhoneData = actions.map(action => {
    return {
      image: action.mobile_card,
      title: action.title,
      phoneBackground: backgroundImages,
    };
  });
  return (
    <Fragment>
      <div className="is-flex manager-buttons is-hidden-mobile">
        {actions.map(({ title }, i) => (
          <button
            key={ title }
            type="button"
            className={ `manager-button${
              i === activeIndex ? ' manager-button-active' : ''
            }` }
            onClick={ () => setActiveIndex(i) }
          >
            <h4 className="is-size-6 has-text-weight-semibold">{title}</h4>
          </button>
        ))}
      </div>
      <div className="manager-cards">
        <Slider
          activeIndex={ activeIndex }
          actions={ actions }
          faceIcons={ faceIcons }
          mobilePhoneData={ mobilePhoneData }
          notSwipeable={ notSwipeable }
        />
      </div>
      <div className="manager-app-downloads is-hidden-mobile">
        <Link
          to="https://apps.apple.com/us/app/when-i-work-employee-scheduling/id383126470"
          target="_blank"
          style={ { marginRight: '20px' } }
        >
          <img
            loading="lazy"
            className="manager-app-download"
            src={ appLogos.app_store.source_url }
            alt={ appLogos.app_store.alt_text }
          />
        </Link>
        <Link
          to="https://play.google.com/store/apps/details?id=com.thisclicks.wiw"
          target="_blank"
        >
          <img
            loading="lazy"
            className="manager-app-download"
            src={ appLogos.google_play.source_url }
            alt={ appLogos.google_play.alt_text }
          />
        </Link>
      </div>
    </Fragment>
  );
};

export default Phones;
