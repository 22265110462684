import { useModal } from 'react-modal-hook';
import { graphql, useStaticQuery } from 'gatsby';

import Link from 'components/link/link';
import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';

import './styles/landing.scss';
import Button from '../buttons/button';
import Signups from '../modal/signups';

type NavigationLandingProps = {
  trialLength?: number
}

const NavigationLanding = ({ trialLength }:NavigationLandingProps) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              nav_logo {
                source_url
                alt_text
              }
            }
          }
        }
      }
    }
  `);

  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } />
  ));

  return (
    <nav className="navigation-landing" data-section="landingPageNavigation">
      <div className="container">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-one-quarter-desktop is-one-half-mobile">
            <Link className="logo-link" to="/" title="When I Work - Home">
              <img
                className="logo"
                src={
                  data.allWordpressAcfOptions.edges[0].node.options.nav_logo
                    .source_url
                }
                alt={
                  data.allWordpressAcfOptions.edges[0].node.options.nav_logo
                    .alt_text || 'When I Work Logo - To Home Page'
                }
              />
            </Link>
          </div>
          <div className="column is-three-quarters-desktop is-one-half-mobile has-text-right buttons nav-items">
            <Button
              type="button"
              className="button is-rounded intercom-launch-demo"
              text="Request a Demo"
              onClick={ showScheduleDemoModal }
            />
            <Signups
              uniqueId="landingNavigation"
              longPlaceholder={ true }
              showInitFields={ false }
              fieldLayout="row"
              buttonClasses="button is-signup-button is-rounded is-hidden-mobile"
              showModalButton={ true }
              trialLength={ trialLength }
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationLanding;
