import { ReactNode, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';

import Footer from 'components/footer/default';
import { SignUpPlanIds } from 'components/forms/constants';
import Head from 'components/globals/head';
import NavigationLanding from 'components/navigation/landing';

ReactModal.setAppElement('#___gatsby');

type LayoutLandingProps = {
  children: ReactNode
  footerSignupButtonText?: string,
  footerSignupHeader?: string,
  legalText?: string,
  showInitFields?: boolean,
  showScheduleDemoButton?: any,
  signUpRefPlanId?: SignUpPlanIds,
  signupModalSubmit?: string,
  trialLength?: number
}
const Layout = ({ 
  children,
  showInitFields,
  signupModalSubmit,
  legalText,
  footerSignupButtonText,
  footerSignupHeader,
  signUpRefPlanId,
  showScheduleDemoButton,
  trialLength,
}:LayoutLandingProps) => {
  const [showFields, setShowFields] = useState(true);

  useEffect(() => {
    if (showInitFields === false) {
      setShowFields(false);
    } else {
      setShowFields(true);
    }
  }, [showInitFields]);

  return (
    <div className="layout-wrapper">
      <Head />
      <ModalProvider>
        <NavigationLanding trialLength={ trialLength } />
        <div role="main">{children}</div>
        <Footer
          showInitFields={ showFields }
          signupModalSubmit={ signupModalSubmit }
          legalText={ legalText }
          footerSignupHeader={ footerSignupHeader }
          footerSignupButtonText={ footerSignupButtonText }
          signUpRefPlanId={ signUpRefPlanId }
          trialLength={ trialLength }
          showScheduleDemoButton={ showScheduleDemoButton }
        />
      </ModalProvider>
    </div>
  );
};

export default Layout;
