import { useState } from 'react';

import TwoByTwo from 'components/content-blocks/partials/two-by-two';
import XByX from 'components/content-blocks/partials/x-by-x';

import './styles/features-grid.scss';

type FeatureGridProps = {
  buttons: Buttons
  className: string,
  columns?: number,
  sub_title: string,
  title: string
}

type Buttons = [{
    desktopScreenshot?: string
    desktop_screenshot?: {
      source_url: string
    }
}]

const FeatureGrid = ({ title, sub_title, buttons, className, columns }:FeatureGridProps) => {

  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  return (
    <section
      className={ `features-grid-section features-grid ${className || ''}` }
    >
      <div className="interactive-content">
        <header className="section-header">
          <h2 className="subheadline">{sub_title}</h2>
          <h3 className="headline">{title}</h3>
        </header>
        {columns ?
          <XByX
            features={ buttons }
            activeFeatureIndex={ activeFeatureIndex }
            setActiveFeatureIndex={ setActiveFeatureIndex }
            x = { columns }
          /> :
          <TwoByTwo
            features={ buttons }
            activeFeatureIndex={ activeFeatureIndex }
            setActiveFeatureIndex={ setActiveFeatureIndex }
          />
        }
      </div>
      <div className="laptop-with-bubble">
        <img
          loading="lazy"
          className="laptop-image"
          src="https://marketing-assets.wheniwork-production.com/2020/04/13144341/Laptop.svg"
          alt="Laptop with When I Work application open"
          width="1200"
          height="728"
        />
        <img
          loading="lazy"
          className="features-grid-bubble"
          src={
            buttons[activeFeatureIndex].desktopScreenshot ||
            buttons[activeFeatureIndex].desktop_screenshot?.source_url
          }
          alt="Bubble showing close up of app"
        />
        <div className="features-grid-bubble-border" />
      </div>
    </section>
  );
};

export default FeatureGrid;
