import 'components/buttons/styles/button-toggle-switch.scss';

type ButtonToggleSwitchProps = {
  id: string;
  label: string;
  toggleState: boolean;
  updateToggleState?: () => void;
};
const ButtonToggleSwitch = ({
  toggleState,
  updateToggleState,
  id,
  label,
}: ButtonToggleSwitchProps) => {
  return (
    <div className="button-toggle-switch">
      <div className="toggle">
        <input
          type="checkbox"
          id={ id }
          onChange={ () => updateToggleState ? updateToggleState() : false }
          checked={ toggleState }
        />
        <label htmlFor={ id }>{label}</label>
        <span className="toggle-on">ON</span>
        <span className="toggle-off">OFF</span>
      </div>
    </div>
  );
};

export default ButtonToggleSwitch;
