import { graphql, useStaticQuery } from 'gatsby';

type AwardBadgesProps = {
  awardCount?: number,
  filterCategory?: string
}
const DEFAULT_CATEGORY  = 'universal';
const AwardCount = 4;

const AwardBadges = ({ filterCategory = DEFAULT_CATEGORY, awardCount = AwardCount }: AwardBadgesProps) => {
  const data = useStaticQuery(graphql`
 {
    allWordpressAcfOptions {
      edges {
        node {
          options {
            award_badges {
              badges {
                badge {
                  source_url
                }
                category
              }
            }
          }
        }
      }
    }
  }
  `);

  const badges = data.allWordpressAcfOptions.edges[0].node.options.award_badges.badges;

  function generateAwards() {
    const categoryBadges: any = [];
    const universalBadges: any = [];
    badges.forEach(award => {
      if (filterCategory !== DEFAULT_CATEGORY  && award.category === filterCategory) {
        categoryBadges.push(award);
      } else if (award.category === DEFAULT_CATEGORY) {
        universalBadges.push(award);
      }
    });

    return [...categoryBadges, ...universalBadges].slice(0, awardCount).map((badges, index) => (<img key={ index } className="award" loading="lazy" src={ badges.badge.source_url } role="presentation" />));
  }

  return (
    <div className="awards">{generateAwards()}</div>
  );
};

export default AwardBadges;
