import { useEffect, useState } from 'react';

const PhoneSlides = ({
  i,
  className,
  image,
  phoneBackground,
  faceIcons,
  ...props
}) => {
  const [phoneBg, updatePhoneBg] = useState();
  const [faceIcon, updateFaceIcon] = useState();
  const [dialogDirection, setDialogDirection] = useState();
  const title = props.title || '';
  const copy = props.copy || '';
  const faceIconMobile = faceIcons.center.source_url;

  useEffect(() => {
    if (i === 0) {
      updatePhoneBg(phoneBackground.left_and_right.source_url);
      updateFaceIcon(faceIcons.left.source_url);
      setDialogDirection('left');
    } else if (i === 1) {
      // if the slide has an i of 1 then it is the center slide and needs the center phone background
      updatePhoneBg(phoneBackground.center.source_url);
      updateFaceIcon(faceIcons.center.source_url);
      setDialogDirection('center');
    } else {
      updatePhoneBg(phoneBackground.left_and_right.source_url);
      updateFaceIcon(faceIcons.right.source_url);
      setDialogDirection('right');
    }
  }, []);

  return (
    <div className={ `is-flex is-column has-margin-x-auto ${className}` }>
      <div className={ `card is-relative ${i > -1 ? `card-${i}` : ''}` }>
        <img
          loading="lazy"
          className={ `card-phone-screen card-phone-screen-${dialogDirection}` }
          src={ image }
          alt="fill me out"
        />
        <img
          className={ `card-phone-case card-phone-case-${dialogDirection}` }
          src={ phoneBg }
          alt="smartphone case"
        />
        <div
          className={ `card-portrait-dialog card-portrait-dialog-${dialogDirection}` }
        >
          <div className="card-portrait-container is-relative">
            <picture>
              <source srcSet={ faceIcon } media="(min-width: 769px)" />
              <img
                loading="lazy"
                src={ faceIconMobile }
                alt="icon of customer face"
              />
            </picture>
            <div
              className={ `card-dialog card-dialog-${dialogDirection} is-hidden-mobile` }
            >
              <div
                className={ `card-dialog-inner card-dialog-inner-${dialogDirection}` }
              >
                {copy}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={ `title is-hidden-tablet has-text-centered ${
          title === '' ? 'is-hidden' : ''
        }` }
      >
        {title}
      </div>
    </div>
  );
};

export default PhoneSlides;
