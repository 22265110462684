import { useState, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { flatten } from 'lodash';

import PhoneSlides from './slides/phones';

const Slider = ({
  startingIndex,
  activeIndex,
  actions,
  faceIcons,
  mobilePhoneData,
  notSwipeable,
}) => {
  const [itemIndex, setItemIndex] = useState(startingIndex || 0);

  return (
    <Fragment>
      <div className="slider-container slides phones-slider is-hidden-tablet">
        {!notSwipeable ? (
          <Fragment>
            <div className="slides-container">
              <SwipeableViews
                onChangeIndex={ i => setItemIndex(i) }
                style={ { padding: '0px calc(42% - 120px)' } }
                slideStyle={ { padding: '0 10px' } }
                springConfig={ {
                  easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
                  duration: '.8s',
                  delay: '0s',
                } }
                enableMouseEvents
                index={ startingIndex }
              >
                {mobilePhoneData.map((data, index) => (
                  <PhoneSlides
                    key={ `swipeableSlider${index}` }
                    i={ index }
                    activeIndex={ itemIndex }
                    image={ data.image.phone_screen.source_url }
                    title={ data.title }
                    phoneBackground={ data.phoneBackground }
                    faceIcons={ faceIcons }
                  />
                ))}
              </SwipeableViews>
            </div>
            <div className="slides-dots">
              {new Array(flatten(mobilePhoneData.map(data => data)).length)
                .fill(undefined)
                .map((dot, index) => (
                  <div
                    key={ index }
                    className={ `slides-dot ${
                      index === itemIndex ? 'slides-dot-fill' : 'slides-dot-empty'
                    }` }
                  />
                ))}
            </div>
          </Fragment>
        ) : (
          <div className="slides-container vertical-slides">
            {mobilePhoneData.map((data, index) => (
              <PhoneSlides
                key={ `swipeableSlider${index}` }
                i={ index }
                activeIndex={ itemIndex }
                image={ data.image.phone_screen.source_url }
                title={ data.title }
                phoneBackground={ data.phoneBackground }
                faceIcons={ faceIcons }
              />
            ))}
          </div>
        )}
      </div>

      <div className="slider-container slides phones-slider is-hidden-mobile">
        <div className="slides-container">
          {actions.map((data, i) => {
            // This maps through the five groups of three slides
            return data.cards.map((card, index) => {
              // This maps through the three slides in each of the five groups
              return (
                <PhoneSlides
                  key={ `swipeableSlider${index}` }
                  className={ `${activeIndex === i ? 'desktop' : 'is-hidden'}` }
                  i={ index }
                  activeIndex={ activeIndex }
                  copy={ card.copy }
                  image={ card.phone_screen.source_url }
                  phoneBackground={ mobilePhoneData[index].phoneBackground }
                  faceIcons={ faceIcons }
                />
              );
            });
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Slider;
