import ACFImage from 'types/ACFImage';

import Image from 'components/content-blocks/partials/image';
import FontIcon from 'components/icons';

import '../styles/partial/default-landing-hero.scss';

type Props = {
  desktop_image: ACFImage
  feature_text_1: string
  feature_text_2: string
  feature_text_3: string
  mobile_image: ACFImage
}

const DefaultLandingHero = ({
  desktop_image,
  mobile_image,
  feature_text_1,
  feature_text_2,
  feature_text_3,
}:Props) => (
  <section className="default-landing-hero-section">
    <Image
      srcSetPath={ mobile_image.source_url }
      src={ desktop_image.source_url }
      alt="When I Work scheduling app screenshot"
    />
    <ul className="features">
      <li>
        <span>
          <FontIcon icon="checkCircle" />
          {feature_text_1}
        </span>
      </li>
      <li>
        <span>
          <FontIcon icon="checkCircle" />
          {feature_text_2}
        </span>
      </li>
      <li>
        <span>
          <FontIcon icon="checkCircle" />
          {feature_text_3}
        </span>
      </li>
    </ul>
  </section>
);

export default DefaultLandingHero;