import AwardBadges from 'components/globals/award-badges';

import './styles/customer-feature-benefit.scss';
type CustomerFeatureBenefitProps = {
  className: string,
  copy: string,
  customer: Customer,
  headlineTextFormat?: boolean,
  quote: boolean,
  stats: Stats,
  sub_title: string,
  title: string
}

type Customer = {
  business: string,
  name: string,
  photo_desktop: {
    source_url: string
  }
  photo_mobile: {
    alt_text: string,
    source_url: string
  }
  since: string
}

type Stats ={
  descriptor_1: string,
  descriptor_2: string,
  descriptor_3: string,
  value_1: string,
  value_2: string,
  value_3: string
}
const CustomerFeatureBenefit = ({
  stats,
  className,
  quote,
  customer,
  sub_title,
  title,
  copy,
  headlineTextFormat,
}:CustomerFeatureBenefitProps) => {
  const generateAwardsSection = typeof stats === 'undefined' || stats.value_1 === '' ? (
    <AwardBadges />
  ) : (
    <div className="stats-container">
      <div className="stats-stat">
        <p className="stats-value">{stats.value_1}</p>
        <p className="stats-descriptor">{stats.descriptor_1}</p>
      </div>
      <div className="stats-stat">
        <p className="stats-value">{stats.value_2}</p>
        <p className="stats-descriptor">{stats.descriptor_2}</p>
      </div>
      <div className="stats-stat">
        <p className="stats-value">{stats.value_3}</p>
        <p className="stats-descriptor">{stats.descriptor_3}</p>
      </div>
    </div>
  );


  const generateCustomerInfo =
      <div className="customer-info">
        <p className="customer-info-name">{customer.name}</p>
        <p className="customer-info-business">{customer.business}</p>
        <p className="customer-info-since">{customer.since}</p>
      </div>;
  

  return (
    <section
      className={ `customer-feature-benefit-section industry-card ${className}` }
    >
      <div className="industry-card-container">
        <div className="industry-card-customer">
          <picture className="industry-card-image">
            <source
              srcSet={ customer.photo_desktop.source_url }
              media="(min-width: 992px)"
            />
            <img
              loading="lazy"
              src={ customer.photo_mobile.source_url }
              alt={ customer.photo_mobile.alt_text || 'happy customer photo' }
            />
            { !quote && generateCustomerInfo}
          </picture>
        </div>
        <div className="industry-card-details">
          {!quote && <h2 className="subheadline">{title}</h2>}
          {headlineTextFormat ? <p className="headline">{sub_title}</p> : <h3 className="headline">{sub_title}</h3>}
          <p className="body">{copy}</p>
          { quote ? generateCustomerInfo : generateAwardsSection}
        </div>
      </div>
    </section>
  );
};

export default CustomerFeatureBenefit;