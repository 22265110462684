import { useEffect, useState } from 'react';

import FontIcon from 'components/icons';
import Link from 'components/link/link';
import SchedulingSignup from 'components/modal/signups';

type SlidesProps = {
  Wysiwyg?: any,
  activeIndex?: number,
  avatar?: {
    alt_text: string,
    source_url: string
  },
  card_link?: string,
  card_link_title?: string,
  copy: string,
  hasAvatar?: boolean,
  hasCopy?: boolean, 
  hasCta?: boolean,
  hasFontIcon?: boolean,
  hasHeader?: boolean,
  hasImage?: boolean,
  hasOneSlideVisible?: boolean,
  hasShadow?: boolean,
  hasTitle?: boolean,
  hasWysiwyg?: boolean,
  header?: string,
  icon?: string,
  id?: number,
  image?: 
  {
    alt_text: string,
    source_url: string
  },
  index?: number,
  is_active?: boolean,
  link_text?: string,
  link_url?: string,
  modalTitle?: string,
  signupModalSubmit?: string,
  sub_title?: string,
  title?: string
  titleAsHeader?: boolean,
  hasSubTitle?: boolean,
}
const Slides = ({ 
  index,
  activeIndex,
  hasOneSlideVisible,
  link_url,
  card_link,
  link_text,
  card_link_title,
  modalTitle,
  signupModalSubmit,
  is_active,
  id,
  hasShadow,
  hasHeader,
  header,
  hasImage,
  image,
  hasFontIcon,
  icon,
  hasTitle,
  hasAvatar,
  avatar,
  title,
  hasSubTitle,
  sub_title,
  hasWysiwyg, 
  Wysiwyg,
  hasCopy,
  hasCta,
  titleAsHeader,
  copy,
}: SlidesProps) => {

  const [isSlideVisible, setIsSlideVisible] = useState(
    hasOneSlideVisible === true ? 'is-hidden' : ''
  );

  useEffect(() => {
    if (hasOneSlideVisible === true) {
      if (activeIndex === index) {
        setIsSlideVisible('');
      } else {
        setIsSlideVisible('is-hidden');
      }
    }
  });

  const HasCta = () => {
    if (link_url || card_link) {
      return <HasCtaLink />;
    }
    return <HasCtaSignup />;
  };

  const HasCtaLink = () => (
    <Link
      to={ link_url || card_link }
      className="card-link cta-button"
    >
      {link_text || card_link_title}
    </Link>
  );

  const HasCtaSignup = () => (
    <SchedulingSignup
      modalTitle={ modalTitle }
      uniqueId="Card"
      signupModalSubmit={ signupModalSubmit ||  'Start Free Trial' }
      signupModalLaunch="Start Free Trial"
      buttonClasses="cta-button"
      showModalButton
    />
  );

  return (
    <div
      className={ `card ${
        card_link === '' || is_active ? 'card-active' : ''
      }${
        id && id > -1 ? `card-${id}` : ''
      }${isSlideVisible} ${hasShadow && 'has-shadow-large'}` }
    >
      <div className="card-container">
        {typeof hasHeader !== 'undefined' && (
          <h2 className="card-headline has-padding-bottom-md">
            {header}
          </h2>
        )}
        {hasImage === true && (
          <div className="card-img-container">
            <img
              loading="lazy"
              className="card-img"
              src={ image ? image?.source_url : '' }
              role="presentation"
            />
          </div>
        )}
        {hasFontIcon && <FontIcon icon={ icon } />}
        {hasTitle && !titleAsHeader && (
          <p className="card-title">
            {hasAvatar && 
              <img
                loading="lazy"
                src={ avatar && avatar?.source_url } 
                alt={ avatar && avatar?.alt_text }
              />
            }
            {title}
          </p>
        )}
        {hasTitle && titleAsHeader && (
          <h2 className="card-title">
            {hasAvatar &&
              <img
                loading="lazy"
                src={ avatar && avatar?.source_url }
                alt={ avatar && avatar?.alt_text }
              />
            }
            {title}
          </h2>
        )}
        {hasSubTitle && (
          <p className="card-sub-title">{sub_title}</p>
        )}
        {typeof hasWysiwyg !== 'undefined' && (
          <div
            className="card-wysiwyg"
            dangerouslySetInnerHTML={ { __html: Wysiwyg } }
          />
        )}

        {typeof hasCopy !== 'undefined' && (
          <p className="card-copy">{copy} </p>
        )}
        {typeof hasCta !== 'undefined' && <HasCta />}
      </div>
    </div>
  );
};

export default Slides;
