import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { flatten } from 'lodash';

import Slides from './slides/slides';

const Slider = ({ startingIndex, activeIndex, modalTitle, ...props }) => {

  const breakpoints = useBreakpoint();

  const mobile = props.mobile ? props.mobile : breakpoints.sm;
  const tablet = props.tablet ? props.tablet : breakpoints.md;
  const desktop = props.desktop ? props.desktop : breakpoints.l;

  const [itemIndex, setItemIndex] = useState(activeIndex ?? 0);

  const sliderStyle = props.sliderStyle
    ? props.sliderStyle
    : { padding: '0px calc(42% - 120px)',
      overflowX: 'clip', 
    };

  const hasOneSlideVisible = props.hasOneSlideVisible || false;
  const titleAsHeader = props.titleAsHeader || false;

  // This tells customer-stories.js which ActiveIndustryIndex should be which determines if and when the industry-button titles change
  useEffect(() => {
    if (props.usingActiveIndustryIndex) {
      props.setActiveIndustryIndex(itemIndex);
    }
  }, [itemIndex]);

  if (breakpoints.sm && !props.notSwipeable) {
    return (
      <div className={ `slider-container slides swiper ${props.className}` }>
        <div className="slides-container">
          <SwipeableViews
            onChangeIndex={ i => setItemIndex(i) }
            style={ sliderStyle }
            slideStyle={ { padding: '0 10px' } }
            springConfig={ {
              easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
              duration: '.8s',
              delay: '0s',
            } }
            enableMouseEvents
            index={ startingIndex }
          >
            {flatten(
              props.card.map((card, index) => (
                <Slides
                  key={ `swipeableSlider${index}` }
                  { ...card }
                  index={ index }
                  activeIndex={ activeIndex }
                  hasCta={ props.hasCta }
                  hasSubTitle={ props.hasSubTitle }
                  hasTitle={ props.hasTitle }
                  titleAsHeader={ titleAsHeader }
                  hasCopy={ props.hasCopy }
                  hasImage={ props.hasImage }
                  hasHeader={ props.hasHeader }
                  hasShadow={ props.hasShadow }
                  hasWysiwyg={ props.hasWysiwyg }
                  hasFontIcon={ props.hasFontIcon }
                  hasAvatar={ props.hasAvatar }
                  modalTitle={ modalTitle }
                  mobile={ mobile }
                  tablet={ tablet }
                  desktop={ desktop }
                  image={ card.icons ? card.icons.mobile_icon : card.image }
                />
              ))
            )}
          </SwipeableViews>
        </div>
        {props.hasNoDots ? (
          ''
        ) : (
          <div className="slides-dots">
            {new Array(flatten(props.card.map(card => card)).length)
              .fill(undefined)
              .map((_, index) =>
                index === itemIndex ? (
                  <div key={ index } className="slides-dot slides-dot-fill" />
                ) : (
                  <div key={ index } className="slides-dot slides-dot-empty" />
                )
              )}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={ `slider-container slides ${props.className}` }>
        <div className={ `slides-container ${props.notSwipeable ? 'vertical-slides' : ''}` }>
          {props.card.map((card, index) => (
            <Slides
              key={ `slider${index}` }
              { ...card }
              index={ index }
              activeIndex={ activeIndex }
              hasOneSlideVisible={ hasOneSlideVisible }
              hasCta={ props.hasCta }
              hasSubTitle={ props.hasSubTitle }
              hasTitle={ props.hasTitle }
              titleAsHeader={ titleAsHeader }
              hasCopy={ props.hasCopy }
              hasHeader={ props.hasHeader }
              hasShadow={ props.hasShadow }
              hasWysiwyg={ props.hasWysiwyg }
              hasFontIcon={ props.hasFontIcon }
              hasAvatar={ props.hasAvatar }
              hasImage={ props.hasImage ? props.hasImage : false }
              image={ card.icons ? card.icons.desktop_icon : card.image }
              modalTitle={ modalTitle }
            />
          ))}
        </div>
      </div>
    );
  }
};

export default Slider;
